
// Vue ancienne pour la visualisation des rôles applicatifs d'un utilisateur 

<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>


    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="DG Auth / consultation" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear
          indeterminate
          :active="loading || running"
        ></v-progress-linear>

        <v-card flat outlined class="mx-auto">
          <v-card-title class="font-weight-regular"
            >Rechercher un collaborateur
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              :items="users"
              v-model="user"
              :search-input.sync="search"
              no-data-text
              clearable
              @input="selected"
              :loading="loading || running"
            ></v-autocomplete>
          </v-card-text>

          <v-card-text>
            <span>Identifiant du collaborateur :{{ userid }}</span>
          </v-card-text>

          <!-- <v-card-text>
            <v-card class="d-flex flex-wrap" flat>
              <v-card
                v-for="(item, i) in userServices"
                :key="'A' + i"
                :color="color(i)"
                dark
                flat
                class="mx-1"
              >
                <v-card-title class="headline"> {{ item.name }} </v-card-title>
              </v-card>
            </v-card>
            <v-card class="d-flex flex-wrap" flat>
              <v-card
                v-for="(item, i) in userFunctions"
                :key="'B' + i"
                :color="color(i + userServices.length)"
                dark
                flat
                class="mx-1 my-3"
              >
                <v-card-title class="headline"> {{ item.name }} </v-card-title>
              </v-card>
            </v-card>
          </v-card-text> -->

          <v-card-text>
            <v-list dense v-show="userServices.length > 0">
              <v-subheader>Les services</v-subheader>
              <v-list-item-group>
                <v-list-item v-for="(item, i) in userServices" :key="'A' + i">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-subheader>Les fonctions</v-subheader>
              <v-list-item-group>
                <v-list-item v-for="(item, i) in userFunctions" :key="'B' + i">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-subheader>Les rôles autorisés pour les services</v-subheader>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in authorizedRolesByService"
                  :key="'C' + i"
                >
                  <!-- <v-avatar color="indigo" size="48">
                    <span class="white--text caption font-weight-thin">{{
                      silo(item)
                    }}</span>
                  </v-avatar> -->
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-divider />

              <!-- <v-subheader>Les rôles refusés pour les services</v-subheader>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in deniedRolesByService"
                  :key="'D' + i"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group> -->

              <!-- <v-divider /> -->

              <v-subheader
                >Les rôles autorisés pour les fonctions digitales</v-subheader
              >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in authorizedRolesByFunction"
                  :key="'E' + i"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-divider />

              <!-- <v-subheader
                >Les rôles refusés pour les fonctions digitales</v-subheader
              >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in deniedRolesByFunction"
                  :key="'F' + i"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-divider /> -->

              <v-subheader
                >Les rôles autorisés spécifiquement pour le
                collabarateur</v-subheader
              >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in authorizedRolesByUser"
                  :key="'G' + i"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-divider />

              <!-- <v-subheader
                >Les rôles refusés spécifiquement pour le
                collabarateur</v-subheader
              >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in deniedRolesByUser"
                  :key="'H' + i"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-divider /> -->

              <v-divider />

              <v-subheader
                >Les rôles applicatifs pour le collaborateur</v-subheader
              >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in applicationRoles"
                  :key="'I' + i"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="red accent-1" v-if="!item.enabled">
                      mdi-minus-circle-off-outline
                    </v-icon>
                    <v-icon color="green accent-1" v-else> mdi-plus </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>

              <v-divider />
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

// import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { UsersService } from "@/service/user/dg_user_service.js";
import DgAuthService from "@/service/dgauth/dgauth_service.js";

import { build } from "@/service/roles/roles_application";
import * as roleService from "@/service/role_service.js"

export default {
  name: "UserView",

  components: { Workflow, TitleAndReturnComponent },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      running: false,

      /** le service de chargement. */
      serviceUsers: null,

      /**Le service de gestion des droits. */
      serviceDgAuth: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les utilisateurs retournés lors de la recherche incrémentale. */
      users: [],

      /**le référent sélectionné */
      user: null,

      /**l'email pour la recherche incrémentale. */
      search: null,

      lastSearchTimestamp: Date.now(),

      /**les services du collaborateur */
      userServices: [],

      /**les fonctions du collaborateur */
      userFunctions: [],

      /**les rôles du collaborateur */
      userRoles: [],

      authorizedRolesByService: [],
      deniedRolesByService: [],
      authorizedRolesByFunction: [],
      deniedRolesByFunction: [],
      authorizedRolesByUser: [],
      deniedRolesByUser: [],

      applicationRoles: [],

      userid: undefined,

      colors: [
        "red",
        "pink",
        "purple",
        "deep-purple",
        "indigo",
        "blue",
        "light-blue",
        "cyan",
        "teal",
        "green",
        "light-green",
        "lime",
        "yellow",
        "amber",
        "orange",
        "deep-orange",
        "brown",
        "blue-grey",
        "grey",
      ],
    };
  },
  methods: {
    color(index) {
      let i = Math.min(this.colors.length - 1, index);
      return this.colors[i];
    },
    async load() {},
    findUser(email) {
      if (!email || email == "") {
        //le texte de recherche est vide, on resert

        this.user = null;
        this.users = [];
        return;
      }

      if (email.length < 3) {
        this.users = [];
        return;
      }

      let now = Date.now();
      this.lastSearchTimestamp = now;

      this.loading = true;

      //on lance la recherche par email
      this.serviceUsers
        .searchDgUserByEmailWithId(email, now)
        .then((results) => {
          //on vérifie que l'on ne garde pas une réponse plus ancienne (à cause du traitement de la requête)
          if (results.ts < this.lastSearchTimestamp) {
            //logger.error("trop tard");
            return;
          }

          // logger.debug(JSON.stringify(results, null, 4));

          let users = [];
          results.users.forEach((user) => {
            users.push({ text: user.email, value: user });
          });

          this.users = users;
        })
        .catch((err) => {
          console.error("problème lors de la recherche par email:" + err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    selected(value) {
      if (!value) {
        this.reset();
        return;
      }

      this.userid = value.id;

      this.running = true;

      this.applicationRoles.forEach((r) => (r.enabled = false));

      this.serviceDgAuth
        .getDetailForUser(value.id)
        .then((result) => {
          this.userServices = result.services;
          this.userFunctions = result.functions;
          this.userRoles = result.roles;

          this.authorizedRolesByService = roleService.getApmHeritageRoles(result.roles.services.authorized);
          //this.deniedRolesByService = result.roles.services.denied;
          this.authorizedRolesByFunction = roleService.getApmHeritageRoles(result.roles.functions.authorized);
          //this.deniedRolesByFunction = result.roles.functions.denied;
          this.authorizedRolesByUser = roleService.getApmHeritageRoles(result.roles.customs.authorized);
          //this.deniedRolesByUser = result.roles.customs.denied;

          let apmRoles = roleService.getApmHeritageRoles([
            ...result.roles.services.authorized,
            ...result.roles.functions.authorized,
            ...result.roles.customs.authorized,
          ]);

          for (let r of this.applicationRoles) {
            r.enabled = this.hasRole(r, apmRoles);
          }
        })
        .finally(() => {
          this.running = false;
        });
    },
    reset() {
      this.userServices = [];
      this.userFunctions = [];
      this.userRoles = [];

      this.authorizedRolesByService = [];
      this.deniedRolesByService = [];
      this.authorizedRolesByFunction = [];
      this.deniedRolesByFunction = [];
      this.authorizedRolesByUser = [];
      this.deniedRolesByUser = [];

      this.userid = undefined;
    },
    silo(value) {
      if (value.includes("_")) {
        return value.split("_")[0];
      } else value;
    },
    /**retourne true si le rôle applicatif est affecté au collaborateur */
    hasRole(applicationRole, apmRoles) {
      let all = true;

      if (applicationRole.enabled) {
        all = applicationRole.enabled;
      } else {
        for (let expected of applicationRole.expected) {
          if (!apmRoles.includes(expected)) {
            all = false;
          }
        }
      }

      if (applicationRole.name == "Éditeur table établissement direction" ) {
        // console.error("role direction edit -->  " + applicationRole.enabled);
        console.error("? role direction edit -->  " + all);
      }            
      
      return all;
    },
  },
  watch: {
    search(value) {
      this.findUser(value);
    },
  },
  mounted() {
    this.serviceUsers = new UsersService(this.$api.getUsersApi());
    this.serviceDgAuth = new DgAuthService(this.$api.getDgAuthApi());

    //on affiche le bouton retour
    this.showBackButton = true;

    this.addStepForWorkflow("Choisir un collaborateur");
    this.addStepForWorkflow("Consulter ses droits");

    this.load();

    let applicationRoles = JSON.parse(JSON.stringify(build()));

    for (let r of applicationRoles) {
      r.enabled = false;
    }

    this.applicationRoles = applicationRoles;
  },
};
</script>

<style>
</style>